import React from 'react'
import img1 from '../../assets/images/sidebar/1.png'
import img2 from '../../assets/images/sidebar/2.jpg'
import img6 from '../../assets/images/sidebar/4.jpg'
import img3 from '../../assets/images/social/1.png'
import img4 from '../../assets/images/social/2.webp'
import img5 from '../../assets/images/social/3.webp'
import { Card, CardBody, CardHeader } from '@material-tailwind/react'

const LeftNav2 = () => {
    const lang = localStorage.getItem('language')


    const allData = [
        {
            id: 1,
            img: img1,
            title: {
                bn: "স্বাস্থ্য বাতায়ন",
                en: "Health Delivery"
            },

        },
        {
            id: 2,
            img: img2,
            title: {
                bn: "ডেঙ্গু প্রতিরোধে করণীয়",
                en: "How to prevent dengue"
            },
            link:'https://bangladesh.gov.bd/site/page/91530698-c795-4542-88f2-5da1870bd50c'

        },
        {
            id: 3,
            img: img6,
            title: {
                bn: "কোভিড-১৯",
                en: "Covid-19"
            },

        }


    ]

    return (
        <div>
            <Card className='p-0 rounded-sm w-full lg:w-48 mt-0 mb-8 lg:my-8'>
                <CardHeader
                    color="green"
                    className="mb-2 shadow-none grid h-8 w-full mx-auto font-bold text-sm place-items-center rounded-t-sm rounded-b-none">
                    {lang === 'en' ? 'National Anthem' : 'জাতীয় সঙ্গীত'}
                </CardHeader>
                <CardBody className='px-1 pb-2 pt-0'>
                    <audio className='w-full h-10' controls src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Amar_Sonar_Bangla_-_official_vocal_music_of_the_National_anthem_of_Bangladesh.ogg"></audio>
                </CardBody>
            </Card>
            <Card className='p-0 rounded-sm w-full lg:w-48 mb-8'>
                <CardHeader
                    color="green"
                    className="mb-2 shadow-none grid h-8 w-full mx-auto font-bold text-sm place-items-center rounded-t-sm rounded-b-none">
                    {lang === 'en' ? 'Social Contact' : 'সামাজিক যোগাযোগ'}
                </CardHeader>
                <CardBody className='px-1 pb-2 pt-0 flex justify-between items-center'>
                    <a target='_blank' href="https://www.facebook.com/nilmrc?mibextid=ZbWKwL"><img className='w-12' src={img3} alt="" /></a>
                    <a target='_blank' href="https://www.youtube.com/"><img className='w-12' src={img4} alt="" /></a>
                    <a target='_blank' href="https://twitter.com/"><img className='w-12' src={img5} alt="" /></a>
                </CardBody>
            </Card>

            <div className='flex flex-col gap-8'>
                {
                    allData.map(data => {
                        return (
                            <div key={data.id}>
                                <Card className='p-0  w-full lg:w-48'>
                                    <CardHeader
                                        color="green"
                                        className="mb-0 shadow-none grid h-8 w-full mx-auto font-bold text-sm place-items-center rounded-t-sm rounded-b-none">
                                        {lang === 'en' ? data.title.en : data.title.bn}
                                    </CardHeader>
                                    <CardBody className='p-0 flex  flex-col items-center '>
                                        <a target='_blank' href={data.link}><img className='w-full rounded-b-sm' src={data.img} alt="" /></a>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>
            <Card className='p-0 rounded-sm w-full lg:w-48 my-8'>
                <CardHeader
                    color="green"
                    className="mb-0 grid h-8 shadow-none w-full mx-auto font-bold text-sm place-items-center rounded-t-sm rounded-b-none">
                    {lang === 'en' ? 'Google Maps' : 'গুগল মানচিত্র'}
                </CardHeader>
                <CardBody className='p-0 rounded-b-sm'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14604.653277395562!2d90.3676916!3d23.7771979!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c1c4ed63e7d9%3A0x7226a29076a2fc15!2sNational%20Institute%20of%20Laboratory%20Medicine%20%26%20Referral%20Center!5e0!3m2!1sen!2sbd!4v1691568492016!5m2!1sen!2sbd" className='w-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </CardBody>
            </Card>
        </div>
    )
}

export default LeftNav2
