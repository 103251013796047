import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes/Routes";
import ScrollToTop from "react-scroll-to-top";
import icon from "../src/assets/images/arrow/a.png"

function App() {
  return (
    <div className="max-w-screen-2xl mx-auto bg-img ">
      <RouterProvider router={router}></RouterProvider>
      <ScrollToTop
        smooth
        component={<img style={{ padding: "8px" }} src={icon} alt="up" />}
      />
    </div>
  );
}

export default App;
