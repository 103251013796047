import People from '../components/People';
import ErrorPage from '../components/shared/ErrorPage';
import About from '../pages/About';
import Accreditation from '../pages/Accreditation';
import Contact from '../pages/Contact';
import CurrentAffairs from '../pages/CurrentAffairs';
import Administration from '../pages/departments/Administration';
import Biochemistry from '../pages/departments/Biochemistry';
import ClinicalPathology from '../pages/departments/ClinicalPathology';
import Hematology from '../pages/departments/Hematology';
import Microbiology from '../pages/departments/Microbiology';
import Pathology from '../pages/departments/Pathology';
import RadiologyAndImaging from '../pages/departments/RadiologyAndImaging';
import Transfusion from '../pages/departments/Transfusion';
import Virology from '../pages/departments/Virology';
import EmergencyNotice from '../pages/EmergencyNotice';
import GalleryDetails from '../pages/GalleryDetails';
import Homepage from '../pages/Homepage';
import ICT from '../pages/ICT';
import AimsAndScope from '../pages/journal/about/AimsAndScope';
import EditorialBoard from '../pages/journal/about/EditorialBoard';
import JournalAbout from '../pages/journal/about/JournalAbout';
import JournalInsight from '../pages/journal/about/JournalInsight';
import Archives from '../pages/journal/articleAndIssues/Archives';
import CurrentIssue from '../pages/journal/articleAndIssues/CurrentIssue';
import JournalArticleIssues from '../pages/journal/articleAndIssues/JournalArticleIssues';
import AuthorGuidelines from '../pages/journal/contribute/AuthorGuidelines';
import JournalContribute from '../pages/journal/contribute/JournalContribute';
import SubmitAManuscript from '../pages/journal/contribute/SubmitAManuscript';
import Journal from '../pages/journal/Journal';
import JournalHome from '../pages/journal/JournalHome';
import NOC from '../pages/NOC';
import Notice from '../pages/Notice';
import PhotoGallery from '../pages/PhotoGallery';
import Subject from '../pages/Subject';
import Test from '../pages/Test';
import VideoGallery from '../pages/VideoGallery';
import VideoGalleryDetails from '../pages/VideoGalleryDetails';
import Main from './Main';

const { createBrowserRouter } = require('react-router-dom');

const router = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
		children: [
			{
				path: '/',
				element: <Homepage />
			},
			{
				path: '/about-us',
				element: <About />
			},
			{
				path: '/contact-us',
				element: <Contact />
			},
			{
				path: '/noc',
				element: <NOC />
			},
			{
				path: '/department-of-administration',
				element: <Administration />
			},
			{
				path: '/department-of-biochemistry',
				element: <Biochemistry />
			},
			{
				path: '/department-of-microbiology',
				element: <Microbiology />
			},
			{
				path: '/department-of-hematology',
				element: <Hematology />
			},
			{
				path: '/department-of-pathology',
				element: <Pathology />
			},
			{
				path: '/department-of-clinical-pathology',
				element: <ClinicalPathology />
			},
			{
				path: '/department-of-virology',
				element: <Virology />
			},
			{
				path: '/department-of-radiology-and-imaging',
				element: <RadiologyAndImaging />
			},
			{
				path: '/department-of-transfusion',
				element: <Transfusion />
			},
			{
				path: '/test',
				element: <Test />
			},
			{
				path: '/journal',
				element: <Journal />,
				children: [
					{
						index: true,
						element: <JournalHome />
					},
					{
						path: 'about',
						element: <JournalAbout />,
						children: [
							{
								index: true,
								element: <AimsAndScope />
							},
							{
								path: 'insight',
								element: <JournalInsight />
							},
							{
								path: 'editorial',
								element: <EditorialBoard />
							}
						]
					},
					{
						path: 'article-issues',
						element: <JournalArticleIssues />,
						children: [
							{
								index: true,
								element: <CurrentIssue />
							},
							{
								path: 'archive',
								element: <Archives />
							}
						]
					},
					{
						path: 'contribute',
						element: <JournalContribute />,
						children: [
							{
								index: true,
								element: <AuthorGuidelines />
							},
							{
								path: 'manuscript',
								element: <SubmitAManuscript />
							}
						]
					}
				]
			},
			{
				path: '/current-affairs',
				element: <CurrentAffairs />
			},
			{
				path: '/ict',
				element: <ICT />
			},
			{
				path: '/photo-gallery',
				element: <PhotoGallery />
			},
			{
				path: '/video-gallery',
				element: <VideoGallery />
			},
			{
				path: '/notice',
				element: <Notice />
			},
			{
				path: '/emergency-notice',
				element: <EmergencyNotice />
			},
			{
				path: '/site-selection/:id',
				element: <Subject />
			},
			{
				path: '/gallery/:id',
				element: <GalleryDetails />
			},

			{
				path: '/video-gallery/:id',
				element: <VideoGalleryDetails />
			},
			{
				path: '/people/:id',
				element: <People />
			},
			{
				path: 'accreditation',
				element: <Accreditation />
			},
			{
				path: '*',
				element: <ErrorPage />
			}
		]
	}
]);
export default router;
