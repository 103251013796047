import { Card, Typography } from '@material-tailwind/react';
import React from 'react';
import { MdSimCardDownload } from 'react-icons/md';
import img from '../assets/images/pages/4.png';

const Test = () => {
	const lang = localStorage.getItem('language');
	return (
		<div>
			<div className="px-0 lg:px-2 mt-2">
				<Card className="rounded-sm w-full">
					{' '}
					<Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
						{lang === 'en' ? 'Exam / Test List' : 'পরীক্ষা / টেস্ট তালিকা'}
					</Typography>
				</Card>
				<img className="mx-auto w-4/5" src={img} alt="" />
				<hr className="my-3" />
				<div>
					<Typography className="font-semibold text-xl">
						{lang === 'en' ? 'Download the list' : 'তালিকাটি ডাউনলোড করুন'}
					</Typography>
					<a
						href="https://drive.google.com/file/d/1yltna1QxXPurd9gXrWS5MPwMX94ER85c/view?usp=drive_link"
						className="text-red-500 text-9xl"
					>
						<MdSimCardDownload />
					</a>
				</div>
			</div>
		</div>
	);
};

export default Test;
