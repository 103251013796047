import React from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
} from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const menuItems = [
  {
    title: {
      bn: "ফটো গ্যালারী",
      en: "Photo Gallery",
    },
    link: "/photo-gallery",
  },
  {
    title: {
      bn: "ভিডিও গ্যালারী",
      en: "Video Gallery",
    },
    link: "/video-gallery",
  },
];

export default function GalleryDrop() {
  const lang = localStorage.getItem("language");
  const [openMenu, setOpenMenu] = React.useState(false);
  const { pathname } = useLocation();
  const isCurrentPath = (path) => {
    return pathname === path;
  };

  const triggers = {
    onMouseEnter: () => setOpenMenu(true),
    onMouseLeave: () => setOpenMenu(false),
  };

  return (
    <Menu open={openMenu} handler={setOpenMenu}>
      <MenuHandler>
        <p
          {...triggers}
          variant="paragraph"
          className={`hover:bg-red-900 flex gap-2 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-[6px]  ${
            isCurrentPath("/photo-gallery") || isCurrentPath("/video-gallery")
              ? "bg-red-900 text-white "
              : ""
          }`}
        >
          {lang === "en" ? "Gallery" : "গ্যালারী"}

          <span className="block lg:hidden">
            <ChevronDownIcon
              strokeWidth={1.5}
              className={`h-3.5 w-3.5 transition-transform ${
                openMenu ? "rotate-180" : ""
              }`}
            />
          </span>
        </p>
      </MenuHandler>
      <MenuList
        {...triggers}
        className="w-[4-rem] md:w-[2rem] p-0 overflow-visible ml-10"
      >
        <ul className="col-span-4 flex w-full flex-col ">
          {menuItems.map(({ title, link }) => (
            <Link to={link} className="" key={title.bn}>
              <MenuItem className="p-2">
                <Typography variant="small" color="black" className="text-sm">
                  {lang === "en" ? title.en : title.bn}
                </Typography>
              </MenuItem>
            </Link>
          ))}
        </ul>
      </MenuList>
    </Menu>
  );
}
