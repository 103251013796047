import { Card, Typography } from "@material-tailwind/react";
import img from "../assets/images/pages/5.png";
import React, { useEffect, useState } from "react";
import { server } from "../server";
import { BiSolidDownload } from "react-icons/bi";

const EmergencyNotice = () => {
  const TABLE_HEAD = [
    {
      en: "Sl",
      bn: "ক্রম",
    },
    {
      en: "Subject",
      bn: "বিষয়বস্তু",
    },
    {
      en: "Download",
      bn: "ডাউনলোড",
    },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/news/get-news`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.news);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="px-0 lg:px-2 mt-2">
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Emergency Notice" : "জরুরী নোটিশ"}
          </Typography>
        </Card>
        <img className="mx-auto w-3/5" src={img} alt="" />
        <hr className="my-3" />
        <div>
          <Card className="h-full w-full overflow-scroll lg:overflow-hidden rounded-sm">
            <table className="w-full table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {lang === "en" ? head.en : head.bn}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map(({ _id, link, title }, index) => {
                  return (
                    <tr key={_id} className="even:bg-blue-gray-50/50 ">
                      <td className="p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm"
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-justify ml-4"
                        >
                          {lang === "en" ? title.en : title.bn}
                        </Typography>
                      </td>
                      <td>
                        <a href={link} target="_blank">
                          {" "}
                          <BiSolidDownload className="bg-green-500 text-white text-2xl rounded-sm ml-8" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmergencyNotice;
