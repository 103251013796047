import { NavLink, Outlet } from 'react-router-dom';

export default function JournalContribute() {
	return (
		<div className="py-2">
			<nav className="navbar rounded-t-sm bg-red-900 text-white py-2 px-4">
				<ul className="flex space-x-4">
					<li>
						<NavLink to="/journal/contribute" end className="hover:underline">
							Author Guidelines
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/journal/contribute/manuscript"
							className="hover:underline"
						>
							Submit a Manuscript
						</NavLink>
					</li>
				</ul>
			</nav>
			<section className="journal-description p-4 bg-gray-100 text-gray-800 rounded-b-sm">
				<Outlet />
			</section>
		</div>
	);
}
