import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

const allData = [
  {
    en: "Human Resource Database",
    bn: "মানব সম্পদ ডাটাবেজ",
    link: "https://hrm.dghs.gov.bd/auth/signin",
  },
];

const HumanResourceDB = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Human Resource Database" : "মানব সম্পদ ডাটাবেজ"}
          </Typography>
        </Card>
      </div>
      <hr className="my-3" />

      <Card className="bg-red-50 rounded-sm">
        <CardBody className="flex justify-center items-center">
          <a
            target="_blank"
            href="https://hrm.dghs.gov.bd/auth/signin"
          >
            <Button color="green" className="bg-green-500 rounded-sm tracking-widest normal-case">
              {lang === "en"
                ? "Click to know about Human Resource Database"
                : "মানব সম্পদ ডাটাবেজ জানতে এখানে ক্লিক করুন"}
            </Button>
          </a>
        </CardBody>
      </Card>

    </div>
  );
};

export default HumanResourceDB;
