import React from 'react';

export default function JournalHome() {
	return (
		<section className="my-2 journal-description p-4 bg-gray-100 text-gray-800 rounded-sm">
			<p className="text-justify mb-2">
				Welcome to the Journal of National Institute of Laboratory Medicine and
				Referral Centre, a peer-reviewed journal. This is an innovative academic
				research journal covering the fields related to health science. The
				journal is published by the JNILMRCB Publication. The Journal aims to
				share new insights into the experimental and clinical medicine,
				molecular and cellular mechanisms of human diseases and high-dimensional
				biology techniques such as genomics and proteomics. We particularly
				encourage submissions on topics such as infectious diseases, epidemic
				and pandemic diseases, clinical evaluation of drugs and therapies,
				articles on all aspects of diagnostic imaging, tissue-based studies,
				biomedical and translational research. The manuscript types include
				original articles, review articles, case studies from the network of
				researchers, clinicians, basic and paraclinical subjects.
			</p>
			<p className="text-justify mb-2">
				National Institute of Laboratory Medicine and Referral Centre is a
				government institution which starts its journey on 2nd December, 2020.
				Along with achieving other milestones NILMRC also published journal from
				June 2021. We have expert panel of editorial board. We are offering a
				venue for exceptional medical research around the world.
			</p>
			<p className="text-justify">
				All articles published in the National Institute of Laboratory Medicine
				and Referral Centre Journal has been archived in our peer-reviewed
				journal. This ensures unfettered access for both readers and researchers
				to use the scientific data. We are looking forward to your insightful
				submissions to the Journal and the dissemination of your findings to the
				international medical community.
			</p>
		</section>
	);
}
