import { Card, Typography } from "@material-tailwind/react";
import img from "../assets/images/pages/2.webp";
import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  const lang = localStorage.getItem("language");
  return (
    <div>
      <div className="px-0 lg:px-2 mt-2">
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Contact" : "যোগাযোগ"}
          </Typography>
        </Card>
        <img className="mx-auto w-3/5" src={img} alt="" />
        <hr className="my-3 " />
        <div className="text-justify leading-loose">
          <Typography variant="paragraph" className="text-xl font-semibold">
            {lang === "en"
              ? "National Institute of Labratory Medicine & Referrel Center (NILMRC) "
              : "ন্যাশনাল ইনস্টিটিউট অব ল্যাবরেটরী মেডিসিন এন্ড রেফারেল সেন্টার (NILMRC) "}
          </Typography>
          <Typography className="leading-loose " variant="paragraph">
            {lang === "en"
              ? "Sher-E-Bangla Nagar, Agargaon, Dhaka-1207, Bangladesh"
              : "শের-ই-বাংলা নগর, আগারগাঁও, ঢাকা-১২০৭, বাংলাদেশ"}
          </Typography>
          <Typography className="leading-loose " variant="paragraph">
            {lang === "en"
              ? "PA to Director: 02-44827350"
              : "পিএ টু ডিরেক্টর: ০২-৪৪৮২৭৩৫০"}
          </Typography>
          <Typography className="leading-loose " variant="paragraph">
            {lang === "en"
              ? "Reception: 01911-909 153"
              : "রিসিপশন: ০১৯১১-৯০৯ ১৫৩ "}
          </Typography>
          <Typography className="leading-loose " variant="paragraph">
            {lang === "en"
              ? "Office Information: 01911-909 141"
              : "অফিস ইনফরমেশন: ০১৯১১-৯০৯ ১৪১ "}
          </Typography>
          
        
          <Typography className="leading-loose " variant="paragraph">
            {lang === "en"
              ? "Covid-19 test's information: +880 1911-909 177"
              : "কোভিড-১৯ পরীক্ষার তথ্যঃ +৮৮০ ১৯১১-৯০৯ ১৭৭"}
          </Typography>
       
        
          <Typography variant="paragraph">
            {lang === "en" ? "Email:" : "ইমেইলঃ"}{" "}
            <a href="mailto: nilmrc@ld.dghs.gov.bd">
              <span className="leading-loose text-green-500 hover:underline hover:text-blue-500">
                nilmrc@ld.dghs.gov.bd
              </span>{" "}
            </a>
          </Typography>
          <Typography variant="paragraph">
            {lang === "en" ? "Website:" : "ওয়েবসাইটঃ"}{" "}
            <Link to="/">
              {" "}
              <span className="leading-loose text-green-500 hover:underline hover:text-blue-500">
                www.nilmrc.gov.bd
              </span>{" "}
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Contact;
