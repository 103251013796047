import { Outlet } from 'react-router-dom';

const Journal = () => {
	return (
		<div className="min-h-[28vh]">
			<Outlet />
		</div>
	);
};

export default Journal;
