import { Card, CardBody, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { server } from "../server";
import Loader from "../components/shared/Loader";

const PhotoGallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/videoGallery/get-video_gallery`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.videoGallery);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="px-0 lg:px-2 mt-2">
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Video Gallery" : "ভিডিও গ্যালারী"}
          </Typography>
        </Card>
        <hr className="my-3" />
        <div>
          {data.map((data) => {
            return (
              <div key={data._id}>
                <Link to={`/video-gallery/${data._id}`}>
                  <Card className="rounded mb-2 hover:bg-red-600 hover:text-white">
                    <CardBody className="flex p-0 justify-between items-center">
                      <div className="w-40 h-28">
                        <img
                          className="w-full rounded-l h-full object-cover"
                          src={data.thumbnail.url}
                          alt=""
                        />
                      </div>
                      <div className="w-[calc(100%-170px)] pr-2">
                        <Typography className="font-medium">
                          {lang === "en" ? data.title.en : data.title.bn}
                        </Typography>
                        <Typography className="text-sm text-justify">
                          {lang === "en"
                            ? data.description.en.slice(0, 190)
                            : data.description.bn.slice(0, 190)}
                          ...
                        </Typography>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
