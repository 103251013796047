import { Chip, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ScrollRestoration, useParams } from "react-router-dom";
import { server } from "../server";
import Loader from "../components/shared/Loader";
import { format } from "date-fns";
import { bn } from "date-fns/locale";

const GalleryDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/gallery/get-gallery`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.gallery);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }
  const item = data.find((i) => i._id === id);

  const desc = item.description.bn.split("।");
  const descEn = item.description.en.split(".");

  let upDescription = "";
  for (let index = 0; index < Math.floor(desc.length / 2); index++) {
    upDescription += desc[index] + "।";
  }
  let upDescriptionEn = "";
  for (let index = 0; index < Math.floor(descEn.length / 2); index++) {
    upDescriptionEn += descEn[index] + ".";
  }

  let downDescription = "";
  for (let index = Math.floor(desc.length / 2); index < desc.length; index++) {
    downDescription += desc[index] + "।";
  }
  let downDescriptionEn = "";
  for (
    let index = Math.floor(descEn.length / 2);
    index < descEn.length;
    index++
  ) {
    downDescriptionEn += descEn[index] + ".";
  }

  const formatDate = (dateString, lang) => {
    const date = new Date(dateString);
    const formatOptions = { day: "numeric", month: "long", year: "numeric" };

    if (lang === "bn") {
      // Convert each digit of the year to Bangla numeral
      const banglaNumerals = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
      const banglaYear = date
        .getFullYear()
        .toString()
        .replace(/\d/g, (digit) => banglaNumerals[digit]);

      return format(date, `do  MMMM, ${banglaYear}`, { locale: bn });
    } else {
      return date.toLocaleDateString("en-US", formatOptions);
    }
  };

  return (
    <div>
      <div className="px-0 lg:px-2 mt-4">
        <Typography className="text-red-600 text-2xl font-semibold">
          {lang === "en" ? item.title.en : item.title.bn}
        </Typography>
        <hr className="my-3" />
        <img
          className="w-full h-96 rounded overflow-hidden"
          src={item.thumbnail.url}
          alt=""
        />
        <Chip
          variant="ghost"
          value={formatDate(item.date, lang)}
          color="red"
          className="rounded-sm max-w-min mt-4 text-red-900"
        />
        <h1 className="my-4 text-justify text-sm">
          {lang === "en" ? upDescriptionEn : upDescription}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {item.images.map((item, index) => {
            return (
              <div key={index} className="h-52 w-76">
                <img
                  className="w-full h-full rounded lg:hover:scale-[1.02] transition hover:rounded-xl ease-in-out lg:hover:drop-shadow-xl"
                  src={item.url}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <h1 className="my-4 text-justify text-sm">
          {" "}
          {lang === "en" ? downDescriptionEn : downDescription}
        </h1>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default GalleryDetails;
