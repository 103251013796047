import React from 'react'
import img1 from '../../assets/images/sidebar/3.jpg'

import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react'

const RightNav2 = () => {
    const lang = localStorage.getItem('language')


    return (
        <div className='w-full'>
            <Card className='p-0 rounded-sm w-full lg:w-48 mt-0 lg:my-8'>
                <CardHeader
                    color="green"
                    className="mb-2 shadow-none grid h-8 w-full mx-auto font-bold text-sm place-items-center rounded-t-sm rounded-b-none">
                    {lang === 'en' ? 'Emergency Contacts' : 'জরুরী হটলাইন'}
                </CardHeader>
                <CardBody className='py-2 w-full px-0 mx-auto'>
                    <img className='w-full rounded-b-md' src={img1} alt="" />
                </CardBody>
            </Card>

        </div>
    )
}

export default RightNav2
