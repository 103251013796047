import ManuscriptSubmissionLetter from '../../../components/ManuscriptSubmissionLetter';

export default function AuthorGuidelines() {
	return (
		<section className="journal-description bg-gray-100 text-gray-800 rounded-sm">
			<div className="flex justify-center mb-4">
				<h1 className="font-bold">
					Information for Contributors (Author guidelines)
				</h1>
			</div>
			<div>
				<h1 className="font-bold ">General Information</h1>
				<p className="text-justify">
					The Journal of National Institute of Laboratory Medicine and Referral
					Centre Bangladesh (JNILMRCB) is a BMDC-recognized journal, published
					twice a year. It accepts original papers, review articles, case
					reports, and short communications related to various disciplines of
					medical science for publication. The paper should be solely
					contributed to the Journal. You may find articles in this journal
					online at  
					<span>
						<a
							className="text-blue-700 underline"
							href="https://www.nilmrc.gov.bd"
							target="_blank"
							rel="noopener noreferrer"
						>
							www.nilmrc.gov.bd
						</a>
					</span>
				</p>
			</div>

			<div className="mt-2">
				<h1 className="font-bold">Manuscripts </h1>
				<p className="text-justify">
					Manuscripts should be prepared following the PubMed style. Format the
					word processing document as double-spaced A4 pages with an additional
					space between paragraphs and margins of at least 2 cm all around. Each
					section should begin on separate pages: Title, Abstract and Keywords,
					Text, Acknowledgements, References, individual Tables, and Legends.
					Use a 12-point standard font such as Times, Helvetica, or Arial (with
					Symbol for special characters). Do not use line numbering, but include
					page numbers in the header or footer, aligned right. Use consistent,
					preferably UK English spelling.
				</p>
			</div>

			<div className="mt-2">
				<h1 className="font-bold">Title page</h1>
				<p className="text-justify">
					The title page should include (i) the type of publication (original
					article, review article, case report, systemic review or
					meta-analysis, book review, etc.) (ii) the complete title of the
					article (iii) the authors’ names in abbreviation (according to PubMed
					style) (iv) list of authors including full name, highest degree,
					signature, designation and institutional affiliation and (v) name,
					mailing address, email and telephone/mobile number of the author
					responsible for correspondence. The title should be clear, simple, and
					concise (not more than 100 words); long titles lack impact.
				</p>
			</div>

			<div className="mt-2">
				<h1 className="font-bold">Title page</h1>
				<p className="text-justify">
					The title page should include (i) the type of publication (original
					article, review article, case report, systemic review or
					meta-analysis, book review, etc.) (ii) the complete title of the
					article (iii) the authors’ names in abbreviation (according to PubMed
					style) (iv) list of authors including full name, highest degree,
					signature, designation and institutional affiliation and (v) name,
					mailing address, email and telephone/mobile number of the author
					responsible for correspondence. The title should be clear, simple, and
					concise (not more than 100 words); long titles lack impact.
				</p>
			</div>

			<div className="mt-2">
				<h1 className="font-bold">Abstracts</h1>
				<p className="text-justify">
					It should begin with the full title of the article. Do not write the
					author's name on the abstract page. The abstracts should not be more
					than 300 words. The abstract should state the purpose of the study or
					investigations, basic procedures, main findings, and principal
					conclusion. Abbreviations and citations should be avoided. Three to
					ten keywords may be provided below the abstract using terms from the
					Medical Subject Headings (Index Medicus, NLM, USA).
				</p>
			</div>

			<div className="mt-2">
				<h1 className="font-bold">Text</h1>
				<p className="text-justify">
					<span>
						The text of the original articles should be divided into the
						following sections: Introduction, Methods, Result, and Discussion.
						Full articles should be no more than 4000 words from the beginning
						of the Introduction to the end of the Discussion.
					</span>
					<span>
						<h1 className="pt-2">Introduction</h1>
						<p className="text-justify">
							It should start on a new page. Essentially this section must
							introduce the subject and briefly say how the idea for research
							originated. Give a concise background of the study. Justification
							for research aims and objectives must be mentioned without any
							ambiguity. It should not exceed 500 words.
						</p>
					</span>
					<span>
						<h1 className="pt-2">Methodology</h1>
						<p className="text-justify">
							This section should deal with the materials used, the study
							design, data collection procedures, statistical methods, and the
							ethical aspects including IRB clearance. If a procedure is a
							commonly used one, giving a reference (previously published) would
							suffice. If a method is not well known (though previously
							published) it is better to describe it briefly.
						</p>
					</span>
					<span>
						<h1 className="pt-2">Results</h1>
						<p className="text-justify">
							The results should be stated concisely without comments. It should
							be presented in logical sequence in the text with appropriate
							reference to tables and/or figures.
						</p>
					</span>
					<span>
						<h1 className="pt-2">Discussion</h1>
						<p className="text-justify">
							Vancouver style This section should deal with the interpretation,
							rather than recapitulation of results. It is important to discuss
							the new and significant observations in light of previous work.
							Discuss also the weaknesses or pitfalls in the study. New
							hypotheses or recommendations can be put forth. The conclusion is
							the last paragraph under Discussion and must be drawn considering
							the strengths and weaknesses of the study. It should tally with
							the objectives stated in the Introduction.
						</p>
					</span>
					<span>
						<h1 className="pt-2">Acknowledgments</h1>
						<p className="text-justify">
							Literature references should be numbered in Arabic numerals in
							superscripts consecutively in the order in which they are
							mentioned in the text. At the end of the article, references must
							be listed following the <strong>Vancouver style</strong>. These
							should be numbered consecutively in the order in which they are
							first mentioned in the text as superscript number. Number of
							references should be kept below 40 except review article.
						</p>
					</span>
				</p>
			</div>

			<div className="mt-2">
				<h1 className="font-bold">Reference</h1>
				<p className="text-justify">
					Literature references should be numbered in Arabic numerals in
					superscripts consecutively in the order in which they are mentioned in
					the text. At the end of the article, references must be listed
					following the <strong>Vancouver style.</strong> These should be
					numbered consecutively in the order in which they are first mentioned
					in the text as superscript number. Number of references should be kept
					below 40 except review article.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Abbreviation</h1>
				<p className="text-justify">
					Standard abbreviations should be used whenever possible. The full term
					for which the abbreviation stands followed by the abbreviation in
					parenthesis should be the first use of the abbreviation in the text
					except for standard units of measurements like 27OC and 25 mmol/L etc.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Foot notes:</h1>
				<p className="text-justify">
					For uniformity of style author should use symbol for footnotes such as
					*.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Tables</h1>
				<p className="text-justify">
					Tables should be typed on separate numbered pages and should follow
					the reference list. All tables should be numbered consecutively using
					Roman numerals. Each must carry a brief descriptive heading. Tables
					should be planned to fit within the print area.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Illustrations</h1>
				<p className="text-justify">
					Photographs and photomicrographs should be of high resolution (minimum
					5 megapixels), in original unedited form and JPG format. These should
					contain a legend with magnification and stain used. The figure number
					and name of the first author should be mentioned in each file. Legend
					should be given on a separate page. Patient identification should be
					hidden.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Manuscript Submission</h1>
				<p className="text-justify">
					Electronic versions of the manuscripts should be submitted through
					email to the Executive Editor. Alternatively send a DVD/CD to: The
					Executive Editor of the JNILMRCB. A cover letter to the
					editor-in-chief must accompany the manuscript stating any, (a)
					conflicts of interest (both financial and personal), (b) that the
					manuscript has not been published previously and is not being
					considered concurrently by another publication, and (c) all authors
					and acknowledged contributors have read and approved the manuscript.
					Submissions are not considered for review if previously published in
					any form (print or online) other than as an abstract. The editor
					reserves the customary right to style and if necessary, shorten the
					material accepted for publication and to determine the priority and
					time of publication. The editor assumes that work is based on honest
					observations. It is not the task of the editor to investigate
					scientific fraud papers.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">The Review Strategy</h1>
				<p className="text-justify">
					On receipt, manuscripts are assessed by the Editor-in-Chief, to one
					Associate Editor. The Reviewers’ and Associate Editor’s views are used
					by the Editor-in-Chief in reaching a decision, usually within four
					weeks of submission.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Proofs</h1>
				<p className="text-justify">
					The corresponding author will be contacted by email once the proofs
					are ready and will be directed to download electronic proofs from a
					secure website. The author should check the proofs carefully, mark any
					printer's errors, and answer queries as requested. Author changes
					should be kept to a minimum. Proof corrections and replacement figures
					(if any) must be returned within 48 hours to avoid any delay in
					publication.
				</p>
			</div>
			<div className="mt-2">
				<h1 className="font-bold">Address of Editorial Office</h1>
				<span>Dr. Sunzida Arina</span>
				<span>Assistant Professor & Executive Editor</span>
				<p>
					Journal of National Institute of Laboratory Medicine and Referral
					Centre Bangladesh (JNILMRCB)
				</p>
				<span>Room No. 1105, Floor: 10th</span>
				<p>
					National Institute of Laboratory Medicine and Referral Centre
					Sher-E-Bangla Nagar, Agargaon, Dhaka, Bangladesh
				</p>
				<span>Email: nilmrc.journal@nilmrc.gov.bd, Phone: + 880244827350</span>
			</div>

			<ManuscriptSubmissionLetter />

			<div className="mt-2">
				<h1 className="font-bold"></h1>
				<p className="text-justify"></p>
			</div>
		</section>
	);
}
