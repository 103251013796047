import Nahid from '../../../assets/images/editorial/Dr. Md. Shahrior Nahid.png';
import Arina from '../../../assets/images/editorial/Dr. Sunzida Arina.png';
import Nafisa from '../../../assets/images/editorial/Dr. Tasnim Nafisa.png';
import Jinnah from '../../../assets/images/editorial/Prof. Dr. Mohammed Shaded Ali Jinnah.png';

export default function EditorialBoard() {
	return (
		<section className="journal-description bg-gray-100 text-gray-800 rounded-sm">
			<div className="grid grid-cols-6 gap-8">
				<div className="col-span-6">
					<h1 className="uppercase font-bold text-lg">Editor in Chief:</h1>
				</div>
				{/* row-1 */}
				<div className="mt-[-17px]">
					<img
						src={Jinnah}
						width={120}
						height={120}
						className="rounded-sm"
						alt="Prof. Dr. Mohammed Shahed Ali Jinnah"
					/>
				</div>
				<div className="mt-[-17px] col-span-5 flex flex-col ">
					<h1 className="font-bold">Prof. Dr. Mohammed Shahed Ali Jinnah</h1>
					<span>Director</span>
					<span>
						National Institute of Laboratory Medicine and Referral Centre
					</span>
					<span>Sher-E-Bangla Nagar, Dhaka-1207</span>
					<span>Mobile: 01711134249</span>
					<span>Email: shahed.jinnah63@gmail.com</span>
				</div>
				<div className="col-span-6">
					<h1 className="uppercase font-bold text-lg">Executive Editor:</h1>
				</div>
				{/* row-2 */}
				<div className="mt-[-17px]">
					<img
						src={Arina}
						width={120}
						height={120}
						className="rounded-sm"
						alt="Dr. Sunzida Arina"
					/>
				</div>
				<div className="mt-[-17px] col-span-5 flex flex-col">
					<span className="font-bold">Dr. Sunzida Arina</span>
					<span>Assistant Professor</span>
					<span>Department of Microbiology</span>
					<span>
						National Institute of Laboratory Medicine and Referral Centre
					</span>
					<span>Sher-E-Bangla Nagar, Dhaka-1207</span>
					<span>Mobile: 01925793294</span>
					<span>Email: s.arina9955@gmail.com</span>
				</div>
				<div className="col-span-6">
					<h1 className="uppercase font-bold text-lg">Assistant Editor:</h1>
				</div>
				{/* row-3 */}
				<div className="mt-[-17px]">
					<img
						src={Nafisa}
						width={120}
						height={120}
						className="rounded-sm"
						alt="Dr. Tasnim Nafisa"
					/>
				</div>
				<div className="mt-[-17px] col-span-5 flex flex-col">
					<span className="font-bold">Dr. Tasnim Nafisa</span>
					<span>Medical Officer, Department of Virology</span>
				</div>
				{/* row-4 */}
				<div>
					<img
						src={Nahid}
						width={120}
						height={120}
						className="rounded-sm"
						alt="Dr. Md. Shahrior Nahid"
					/>
				</div>
				<div className="col-span-5 flex flex-col">
					<span className="font-bold">Dr. Md. Shahrior Nahid</span>
					<span>RMO, Department of Pathology</span>
				</div>

				{/* row-5 */}
				<div className="pt-5">
					<h1 className="font-bold uppercase text-lg">Member:</h1>
				</div>
				<div className="col-span-5 pt-12">
					<div className="flex flex-col mb-4">
						<span className="font-bold">Prof. Dr. Khadiza Khatun</span>
						<span>Professor & Dept. Head of Microbiology</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Prof. Dr. Sohani Ferdousi</span>
						<span>Professor & Dept. Head of Biochemistry</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Dr. Nazmun Naher</span>
						<span>Deputy Director</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Dr. Zohra Khatun</span>
						<span>Associate Professor</span>
						<span>Department of Biochemistry</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Dr. Md. Shahidul Alam</span>
						<span>Assistant Professor</span>
						<span>Department of Radiology and Imaging</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">
							Dr. Mohammad Abdullah Az Zubayer Khan
						</span>
						<span>Associate Professor</span>
						<span>Department of Hematology</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Dr. Jinat Rehena</span>
						<span>Assistant Professor</span>
						<span>Department of Clinical Pathology</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Dr. Mohammad Mahabubul Hoque</span>
						<span>Assistant Professor</span>
						<span>Department of Pathology</span>
					</div>
					<div className="flex flex-col mb-4">
						<span className="font-bold">Dr. Kaniz Fatema</span>
						<span>RMO, Department of Biochemistry</span>
					</div>
				</div>
			</div>
		</section>
	);
}
