import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { MdSimCardDownload } from "react-icons/md";
const TABLE_HEAD = [
  {
    en: "SL",
    bn: "ক্রম",
  },
  {
    en: "Content",
    bn: "বিষয়বস্তু",
  },
  {
    en: "Issue no.",
    bn: "ইস্যু নং",
  },
  {
    en: "Branch",
    bn: "শাখা",
  },
  {
    en: "Published date",
    bn: "প্রকাশের তারিখ",
  },
  {
    en: "Download",
    bn: "ডাউনলোড",
  },
];

const TABLE_ROWS = [
  {
    name: "পার-১ শাখাঃ ৬৪২(সহযোগী অধ্যাপক পদে পদোন্নতি)",
    issue: [
      {
        en: "642",
        bn: "৬৪২",
      },
    ],
    branch: [
      {
        en: "BCS Health-Health Service",
        bn: "বিসিএস হেলথ-হেলথ সার্ভিস",
      },
    ],
    date: [
      {
        en: "2023-10-30",
        bn: "২০২৩-১০-৩০",
      },
    ],
    link: "https://hsd.gov.bd/sites/default/files/files/hsd.portal.gov.bd/go_ultimate/b354a256_2880_4798_8fd3_043240233d9c/Asso-prof_promotion_insitu_hsd-per-1_66_Go-642.pdf",
  },
  {
    name: "পার-১ শাখাঃ ৬৪১(সহকারী অধ্যাপক পদে পদোন্নতি)",
    issue: [
      {
        en: "641",
        bn: "৬৪১",
      },
    ],
    branch: [
      {
        en: "BCS Health-Health Service",
        bn: "বিসিএস হেলথ-হেলথ সার্ভিস",
      },
    ],
    date: [
      {
        en: "2023-10-30",
        bn: "২০২৩-১০-৩০",
      },
    ],
    link: "https://hsd.gov.bd/sites/default/files/files/hsd.portal.gov.bd/go_ultimate/e3e11fae_f0a5_456f_af93_50d6ee3a04df/Asst_prof_promotion_insitu_hsd-per-1_111_Go-641.pdf",
  },
  {
    name: "পার-৩ শাখাঃ ৫৭১,৫৭২",
    issue: [
      {
        en: "571, 572",
        bn: "৫৭১, ৫৭২",
      },
    ],
    branch: [
      {
        en: "BCS Health-Health Service",
        bn: "বিসিএস হেলথ-হেলথ সার্ভিস",
      },
    ],
    date: [
      {
        en: "2023-10-30",
        bn: "২০২৩-১০-৩০",
      },
    ],
    link: "https://hsd.gov.bd/sites/default/files/files/hsd.portal.gov.bd/go_ultimate/b354a256_2880_4798_8fd3_043240233d9c/Asso-prof_promotion_insitu_hsd-per-1_66_Go-642.pdf",
  },
  {
    name: "পার-১ শাখাঃ ৬৩৭(সহযোগী অধ্যাপক পদে পদোন্নতি)",
    issue: [
      {
        en: "367",
        bn: "৩৬৭",
      },
    ],
    branch: [
      {
        en: "BCS Health-Health Service",
        bn: "বিসিএস হেলথ-হেলথ সার্ভিস",
      },
    ],
    date: [
      {
        en: "2023-10-29",
        bn: "২০২৩-১০-২৯",
      },
    ],
    link: "https://hsd.gov.bd/sites/default/files/files/hsd.portal.gov.bd/go_ultimate/b354a256_2880_4798_8fd3_043240233d9c/Asso-prof_promotion_insitu_hsd-per-1_66_Go-642.pdf",
  },
  {
    name: "পার-১ শাখাঃ ৬৩৬(সহযোগী অধ্যাপক পদে পদোন্নতি)",
    issue: [
      {
        en: "636",
        bn: "৬৩৬",
      },
    ],
    branch: [
      {
        en: "BCS Health-Health Service",
        bn: "বিসিএস হেলথ-হেলথ সার্ভিস",
      },
    ],
    date: [
      {
        en: "2023-10-29",
        bn: "২০২৩-১০-২৯",
      },
    ],
    link: "https://hsd.gov.bd/sites/default/files/files/hsd.portal.gov.bd/go_ultimate/b354a256_2880_4798_8fd3_043240233d9c/Asso-prof_promotion_insitu_hsd-per-1_66_Go-642.pdf",
  },
];
const BCSHealth = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en"
              ? "BCS Health / Health Service"
              : "বিসিএস হেলথ / হেলথ সার্ভিস"}
          </Typography>
        </Card>
      </div>
      <hr className="my-3" />

      <Card className="h-full w-full overflow-scroll lg:overflow-hidden rounded-sm">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {lang === "en" ? head.en : head.bn}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(({ name, issue, date, branch, link }, index) => (
              <tr key={name} className="even:bg-blue-gray-50/50">
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {index + 1}
                  </Typography>
                </td>
                <td className="p-4 w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {name}
                  </Typography>
                </td>
                <td className="p-4">
                  {issue.map((i, idx) => {
                    return (
                      <div>
                        {" "}
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {lang === "en" ? i.en : i.bn}
                        </Typography>
                      </div>
                    );
                  })}
                </td>
                <td className="p-4">
                  {branch.map((i, idx) => {
                    return (
                      <div>
                        {" "}
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {lang === "en" ? i.en : i.bn}
                        </Typography>
                      </div>
                    );
                  })}
                </td>
                <td className="p-4 ">
                  {date.map((i, idx) => {
                    return (
                      <div>
                        {" "}
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {lang === "en" ? i.en : i.bn}
                        </Typography>
                      </div>
                    );
                  })}
                </td>

                <td className="p-4">
                  <a target="_blank" href={link}>
                    {" "}
                    <Typography className="font-medium text-red-600 text-3xl">
                      <MdSimCardDownload />
                    </Typography>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default BCSHealth;
