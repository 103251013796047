import emailjs from '@emailjs/browser';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { manuScript } from '../utils/email';

const ManuscriptSubmissionForm = () => {
	const [formData, setFormData] = useState({
		author: '',
		paper: '',
		email: '',
		country: '',
		file: null,
		message: ''
	});

	const [isSending, setIsSending] = useState(false);
	const [error, setError] = useState();

	const successNotification = () => toast.success('Report sent');
	const errorNotification = () => toast.error('The report could not be sent');

	// Handle Input Changes
	const handleChange = (e) => {
		const { name, value, files } = e.target;
		setFormData({
			...formData,
			[name]: files ? files[0] : value
		});
	};

	// Handle Form Submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSending(true);

		const templateParams = {
			from_name: formData.author,
			from_email: formData.email,
			message: manuScript(formData)
		};

		try {
			await emailjs
				.send(
					process.env.REACT_APP_EMAILJS_SERVICE_ID,
					process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
					templateParams,
					process.env.REACT_APP_EMAILJS_PUBLIC_KEY
				)
				.then(() => {
					successNotification();
				})
				.catch((e) => {
					console.log(e);

					errorNotification();
				});
		} catch (error) {
			setError(error);
		} finally {
			setIsSending(false);
			setFormData({
				author: '',
				paper: '',
				email: '',
				country: '',
				file: null,
				message: ''
			});
		}
	};

	return (
		<div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-sm">
			<h1 className="text-3xl font-bold mb-6 text-center">
				Online Submissions
			</h1>
			<h2 className="text-xl font-semibold mb-4">Submit Manuscript</h2>
			<form className="space-y-4" onSubmit={handleSubmit}>
				{/* Author Name */}
				<div>
					<input
						type="text"
						name="author"
						required
						placeholder="Name of the Corresponding Author *"
						className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
						value={formData.author}
						onChange={handleChange}
					/>
				</div>

				{/* Paper Title */}
				<div>
					<input
						type="text"
						name="paper"
						required
						placeholder="Title of the Paper *"
						className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
						value={formData.paper}
						onChange={handleChange}
					/>
				</div>

				{/* Email */}
				<div>
					<input
						type="email"
						name="email"
						required
						placeholder="Enter E-mail *"
						className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
						value={formData.email}
						onChange={handleChange}
					/>
				</div>

				{/* Country */}
				<div>
					<input
						type="text"
						name="country"
						required
						placeholder="Country *"
						className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
						value={formData.country}
						onChange={handleChange}
					/>
				</div>

				{/* File Upload */}
				<div>
					<label className="block text-sm font-medium text-gray-700 mb-1">
						Upload Paper (only docx/doc file) *
					</label>
					<input
						type="file"
						name="file"
						required
						accept=".doc,.docx"
						className="block w-full border border-gray-300 rounded-md file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-red-50 file:text-red-700 hover:file:bg-red-100"
						onChange={handleChange}
					/>
				</div>

				{/* Message */}
				<div>
					<textarea
						placeholder="Your message (optional)"
						name="message"
						rows={5}
						className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
						value={formData.message}
						onChange={handleChange}
					></textarea>
				</div>

				{/* Submit Button */}
				<div className="text-center">
					<p className="bg-red-600">{error ? `${error}` : ''}</p>
					<button
						type="submit"
						className="w-full bg-red-700 text-white py-3 font-semibold rounded-md hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500"
					>
						{isSending ? 'SUBMITTING...' : 'SUBMIT NOW'}
					</button>
					<Toaster />
				</div>
			</form>
		</div>
	);
};

export default ManuscriptSubmissionForm;
