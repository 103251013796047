import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";

const FreeForm = () => {
  const lang = localStorage.getItem("language");
  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Free Form" : "ফ্রি ফরম"}
          </Typography>
        </Card>
      </div>
      <hr className="my-3" />

      <Card className="bg-red-50 rounded-sm">
        <CardBody className="flex justify-center items-center">
          <a
            target="_blank"
            href="https://drive.google.com/file/d/12KQpOK233lbigbInSzhGWVYjiBDGk4zM/view?usp=sharing"
          >
            <Button
              color="green"
              className="bg-green-500 rounded-sm normal-case tracking-widest"
            >
              {lang === "en"
                ? "Click to Download the Free Form"
                : "ফ্রি ফরম ডাউনলোড করতে এখানে ক্লিক করুন"}
            </Button>
          </a>
        </CardBody>
      </Card>
    </div>
  );
};

export default FreeForm;
