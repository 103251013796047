export const manuScript = (data) => {
	const sections = {
		'Name of the Corresponding Author': {
			name: data.author
		},
		'Title of the Paper': {
			paper: data.paper
		},
		'E-mail Address': {
			mail: data.email
		},
		'Country Name': {
			country: data.country
		},

		'Optional Message': {
			message: data.message
		}
	};

	let htmlContent = '';

	Object.entries(sections).forEach(([sectionTitle, fields]) => {
		htmlContent += `
      <div style="margin-bottom: 20px;">
        <h2 style="color: #333; border-bottom: 1px solid #ccc; padding-bottom: 5px;">
          ${sectionTitle}
        </h2>
        <table style="width: 100%; border-collapse: collapse;">
          <tbody>
            ${Object.entries(fields)
							.map(
								([fieldName, value]) => `
                <tr>
                  <td style="padding: 5px; border: 1px solid #ddd; font-weight: bold; width: 200px;">
                    ${fieldName}
                  </td>
                  <td style="padding: 5px; border: 1px solid #ddd;">
                    ${value || '-'}
                  </td>
                </tr>
              `
							)
							.join('')}
          </tbody>
        </table>
      </div>
    `;
	});

	return htmlContent;
};
