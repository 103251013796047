import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import img from "../assets/images/pages/act.png";
import { Link } from "react-router-dom";

const allData = [
  {
    en: "Rare and Specialized Tests",
    bn: "দুর্লভ ও বিশেষায়িত পরীক্ষা",
    link: "/rare-and-specialized-tests",
  },
  {
    en: "Accreditation (Ongoing)",
    bn: "এক্রিডিটেশন (চলমান)",
    link: "/accreditation",
  },
];

const CurrentAffairs = () => {
  const lang = localStorage.getItem("language");

  return (
    <div className="px-0 lg:px-2 mt-2">
      <div>
        <Card className="rounded-sm w-full">
          {" "}
          <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
            {lang === "en" ? "Ongoing Activities" : "চলমান কার্যক্রম"}
          </Typography>
        </Card>
      </div>
      <Card className="grid grid-cols-2 gap-4 rounded-sm p-4">
        {allData.map((data, index) => {
          return (
            <div key={index}>
              <Link to={data.link}>
                <Card className="lg:h-20 h-28 rounded-sm flex justify-center items-center hover:rounded-2xl  hover:drop-shadow-[0px_0px_6px_rgba(217,10,0,0.30)] hover:bg-green-400 transition-all group lg:hover:scale-105">
                  <Typography className="group-hover:text-white text-black">
                    {lang === "en" ? data.en : data.bn}
                  </Typography>
                </Card>
              </Link>
            </div>
          );
        })}
      </Card>
    </div>
  );
};

export default CurrentAffairs;
