const ManuscriptSubmissionLetter = () => {
	return (
		<div className="mt-8">
			<div className="flex flex-col items-center mb-2">
				<h1 className="text-lg font-bold  text-center">
					Cover letter to the editor-in-chief
				</h1>
				<span>
					(to be Signed by the corresponding author on behalf of all authors)
				</span>
			</div>

			{/* Static Content */}
			<div className=" p-6 bg-white border rounded-md shadow-sm">
				<p>To</p>
				<p>The editor-in-chief</p>
				<p>
					Journal of National Institute of Laboratory Medicine and Referral
					Centre Bangladesh
				</p>

				<p className="font-bold mt-4">Subject: Manuscript submission</p>
				<p>Sir,</p>
				<p className="mt-2 text-justify">
					I would like to submit the manuscript entitled{' '}
					<span>“________________________________________”</span>
					By <span>________ et al</span> et al to be considered for publication
					as an original article in the Journal of National Institute of
					Laboratory Medicine and Referral Centre Bangladesh. We declare that
					this manuscript is original, has not been published before, and is not
					currently being considered for publication elsewhere. We know of no
					conflicts of interest associated with this publication, and there has
					been no significant financial support for this work that could have
					influenced its outcome. As the corresponding author, I confirm that
					the manuscript has been read and approved for submission by all the
					named authors.
				</p>
				<p className="mt-2 text-justify">
					We hope you find our manuscript suitable for publication and look
					forward to hearing from you in due course.
				</p>
				<p className="mt-4">Sincerely,</p>
				<p>Sign of the corresponding author</p>
				<p>
					Name, designation, address, Email, and phone/mobile numbers of the
					corresponding author
				</p>
			</div>
		</div>
	);
};

export default ManuscriptSubmissionLetter;
