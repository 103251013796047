import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
	Menu,
	MenuHandler,
	MenuItem,
	MenuList,
	Typography
} from '@material-tailwind/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const menuItems = [
	{
		title: {
			bn: 'হোম',
			en: 'Home'
		},
		link: '/journal'
	},
	{
		title: {
			bn: 'অ্যাবাউট',
			en: 'About'
		},
		link: '/journal/about'
	},
	{
		title: {
			bn: 'আর্টিকেল এবং ইস্যু',
			en: 'Article and Issues'
		},
		link: '/journal/article-issues'
	},
	{
		title: {
			bn: 'অবদান',
			en: 'Contribute'
		},
		link: '/journal/contribute'
	}
];

export default function JournalDrop() {
	const lang = localStorage.getItem('language');
	const [openMenu, setOpenMenu] = React.useState(false);
	const { pathname } = useLocation();
	const isCurrentPath = (path) => {
		return pathname === path;
	};

	const triggers = {
		onMouseEnter: () => setOpenMenu(true),
		onMouseLeave: () => setOpenMenu(false)
	};

	return (
		<Menu open={openMenu} handler={setOpenMenu}>
			<MenuHandler>
				<p
					{...triggers}
					variant="paragraph"
					className={`hover:bg-red-900 flex gap-2 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-[6px] lg:border-l-0  ${
						isCurrentPath('/journal') ||
						isCurrentPath('/journal/about') ||
						isCurrentPath('/journal/about/insight') ||
						isCurrentPath('/journal/article-issues') ||
						isCurrentPath('/journal/contribute') ||
						isCurrentPath('journal/about/insight') ||
						isCurrentPath('/journal/about/editorial') ||
						isCurrentPath('/journal/article-issues/archive') ||
						isCurrentPath('/journal/contribute/manuscript')
							? 'bg-red-900 text-white '
							: ''
					}`}
				>
					{lang === 'en' ? 'Journal Pubications' : 'জার্নাল পাবলিকেশন্স'}

					<span className="block lg:hidden">
						<ChevronDownIcon
							strokeWidth={1.5}
							className={`h-3.5 w-3.5 transition-transform ${
								openMenu ? 'rotate-180' : ''
							}`}
						/>
					</span>
				</p>
			</MenuHandler>
			<MenuList
				{...triggers}
				className="w-[4-rem] md:w-[2rem] p-0 overflow-visible"
			>
				<ul className="col-span-4 flex w-full flex-col">
					{menuItems.map(({ title, link }) => (
						<Link to={link} className="" key={title.bn}>
							<MenuItem className="p-2">
								<Typography variant="small" color="black" className="text-sm">
									{lang === 'en' ? title.en : title.bn}
								</Typography>
							</MenuItem>
						</Link>
					))}
				</ul>
			</MenuList>
		</Menu>
	);
}
