import { NavLink, Outlet } from 'react-router-dom';

export default function JournalAbout() {
	return (
		<div className="py-2">
			<nav className="navbar rounded-t-sm bg-red-900 text-white py-2 px-4">
				<ul className="flex space-x-4">
					<li>
						<NavLink to="/journal/about" end className="hover:underline">
							Aims and Scope
						</NavLink>
					</li>
					<li>
						<NavLink to="/journal/about/insight" className="hover:underline">
							Journal Insight
						</NavLink>
					</li>
					<li>
						<NavLink to="/journal/about/editorial" className="hover:underline">
							Editorial Board
						</NavLink>
					</li>
				</ul>
			</nav>
			<section className="journal-description p-4 bg-gray-100 text-gray-800 rounded-b-sm">
				<Outlet />
			</section>
		</div>
	);
}
