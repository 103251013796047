import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

const People = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/sidebar/get-sidebar`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.sidebar);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const item = data.find((i) => i._id === id);

  return (
    <div className="px-0 lg:px-2 mt-2">
      <Card className="rounded-sm w-full ">
        {" "}
        <Typography className="text-red-600 text-2xl font-semibold p-2 rounded-none">
          {" "}
          {lang === "en" ? item?.designation?.en : item?.designation?.bn}
        </Typography>
      </Card>
      <hr className="my-3" />
      <div>
        <Card className="w-full flex mx-auto flex-col max-w-[52rem] lg:flex-row rounded-sm">
          <CardHeader
            shadow={false}
            floated={false}
            className="m-0  shrink-0 mx-auto rounded-r-none rounded-s-sm relative"
          >
            <img
              src={item?.avatar?.url}
              alt="card-image"
              className=" h-60 w-60 "
            />
            <div className="pl-2 w-60">
              <Typography variant="h6" color="gray" className="my-2 uppercase">
                {lang === "en" ? item?.designation?.en : item?.designation?.bn}
              </Typography>
              <Typography variant="h5" color="blue-gray" className="mb-2">
                {lang === "en" ? item?.name?.en : item?.name?.bn}
              </Typography>
              <Typography color="gray" className="mb-4 font-normal">
                {lang === "en" ? item?.institution?.en : item?.institution?.bn}
              </Typography>
            </div>
          </CardHeader>
          <CardBody className="py-2 overflow-y-scroll h-[22rem] no-scrollbar px-2">
            <Typography className="text-sm text-justify text-black">
              {lang === "en" ? item?.description?.en : item?.description?.bn}
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default People;
