export default function JournalInsight() {
	return (
		<section className="journal-description bg-gray-100 text-gray-800 rounded-sm">
			<div className="overflow-x-auto">
				<table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
					<tbody>
						{/* Row - 1 */}
						<tr className="border-b border-gray-300">
							<td className="w-1/3 px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Journal Title
							</td>
							<td className="px-4 py-3 text-gray-800">
								Journal of National Institute of Laboratory Medicine and
								Referral Centre Bangladesh
							</td>
						</tr>
						{/* Row - 2 */}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Journal Abbreviation
							</td>
							<td className="px-4 py-3 text-gray-800">J Natl Inst Lab Med</td>
						</tr>
						{/* Row - 3*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Acronym
							</td>
							<td className="px-4 py-3 text-gray-800">JNILMRCB</td>
						</tr>
						{/* Row - 4*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Publication Date
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 5*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Indexed to SCI Expanded and JCR Science Edition since
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 6*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Frequency
							</td>
							<td className="px-4 py-3 text-gray-800">2 times per year</td>
						</tr>
						{/* Row - 7*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Publication date
							</td>
							<td className="px-4 py-3 text-gray-800">June 1, December 1</td>
						</tr>
						{/* Row - 8*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Publisher
							</td>
							<td className="px-4 py-3 text-gray-800">NILMRC publication</td>
						</tr>
						{/* Row - 9*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Language
							</td>
							<td className="px-4 py-3 text-gray-800">English</td>
						</tr>
						{/* Row - 10*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								pISSN
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 11*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								eISSN
							</td>
							<td className="px-4 py-3 text-gray-800">2958-471X</td>
						</tr>
						{/* Row - 12*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								DOI Prefix
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 13*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Continues
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 14*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Open Access
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 15*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Electronic Links
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 16*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Indexed/Tracked/ Covered By
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
						{/* Row - 17*/}
						<tr className="border-b border-gray-300">
							<td className="px-4 py-3 font-semibold text-gray-600 bg-gray-100">
								Scopus CiteScore
							</td>
							<td className="px-4 py-3 text-gray-800"></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	);
}
