import React from "react";
import { ScrollRestoration, useParams } from "react-router-dom";
import Employees from "../sections/Employee/Employees";
import NumOfEmp from "../sections/Employee/NumOfEmp";
import Doctor from "../sections/Employee/Doctor";
import MedicalTechnologist from "../sections/Employee/MedicalTechnologist";
import Outsourcing from "../sections/Employee/Outsourcing";
import MissionVission from "../sections/AboutUs/MissionVission";
import Contact from "./Contact";
import Departments from "../sections/AboutUs/Departments";
import Test from "./Test";
import ReportDeliveryTime from "../sections/Notice/ReportDeliveryTime";
import OfficeTime from "../sections/Notice/OfficeTime";
import BloodSampleCollection from "../sections/Notice/BloodSampleCollection";
import HumanResourceDB from "../sections/AboutUs/HumanResourceDB";
import LeaveForm from "../sections/Form/LeaveForm";
import FreeForm from "../sections/Form/FreeForm";
import BCSHealth from "../sections/Orders/BCSHealth";
import ReportDelivery from "../sections/PatientPortal/ReportDelivery";
import NOCForm from "../sections/Form/NOCForm";

const Subject = () => {
  const { id } = useParams();

  return (
    <div>
      {id === "1" && <ReportDeliveryTime />}
      {id === "2" && <OfficeTime />}
      {id === "3" && <BloodSampleCollection />}
      {id === "10" && <MissionVission />}
      {id === "20" && <Departments />}
      {id === "11" && <ReportDelivery />}
      {id === "21" && <Test />}
      {id === "30" && <Contact />}
      {id === "49" && <BCSHealth />}
      {id === "50" && <HumanResourceDB />}
      {id === "60" && <Test />}
      {id === "122" && <NumOfEmp />}
      {id === "222" && <Doctor />}
      {id === "322" && <Employees />}
      {id === "133" && <FreeForm />}
      {id === "233" && <NOCForm />}
      {id === "333" && <LeaveForm />}
      {id === "422" && <MedicalTechnologist />}
      {id === "522" && <Outsourcing />}

      <ScrollRestoration />
    </div>
  );
};

export default Subject;
