import {
	IconButton,
	MobileNav,
	Navbar,
	Typography
} from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { MdHome } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { server } from '../../server';
import DeptDrop from '../navdrop/DeptDrop';
import GalleryDrop from '../navdrop/GalleryDrop';
import JournalDrop from '../navdrop/JournalDrop';
import NoticeDrop from '../navdrop/NoticeDrop';

export function Header() {
	const lang = localStorage.getItem('language');
	const [openNav, setOpenNav] = useState(false);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const { pathname } = useLocation();
	const isCurrentPath = (path) => {
		return pathname === path;
	};
	useEffect(() => {
		fetchData();
	}, []);
	useEffect(() => {
		window.addEventListener(
			'resize',
			() => window.innerWidth >= 960 && setOpenNav(false)
		);
	}, []);
	const fetchData = async () => {
		try {
			const response = await fetch(`${server}/banner/get-marqeue`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const jsonData = await response.json();
			setData(jsonData.data);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
			setLoading(false);
		}
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	const navList = (
		<ul className="mb-4 mt-2 flex flex-wrap gap-y-0 flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
			<Typography
				as="li"
				variant="small"
				color="blue-gray"
				className="font-normal"
			>
				<Link to="/">
					<p
						className={`hover:bg-red-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-2 pl-5 lg:pl-2 py-0 text-[32px] text-red-900 ${
							isCurrentPath('/') ? 'bg-red-900 text-white ' : ''
						}`}
					>
						<MdHome />
					</p>
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="green" className="font-normal">
				<Link
					to="/about-us"
					className={`hover:bg-green-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 lg:border-l-0 px-6 py-2  ${
						isCurrentPath('/about-us') ? 'bg-green-900 text-white ' : ''
					}`}
				>
					{lang === 'en' ? 'About Us' : ' আমাদের সম্পর্কে'}
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="red" className="font-normal">
				<Link
					to="/contact-us"
					className={`hover:bg-red-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2 lg:border-l-0  ${
						isCurrentPath('/contact-us') ? 'bg-red-900 text-white ' : ''
					}`}
				>
					{lang === 'en' ? 'Contact' : 'যোগাযোগ'}
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="green" className="font-normal">
				<Link
					to="/noc"
					className={`hover:bg-green-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2 lg:border-l-0  ${
						isCurrentPath('/noc') ? 'bg-green-900 text-white ' : ''
					}`}
				>
					{lang === 'en' ? 'NOC' : 'এনওসি'}
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="red" className="font-normal ">
				<a href="#">
					<DeptDrop />
				</a>
			</Typography>
			<Typography as="li" variant="small" color="green" className="font-normal">
				<Link
					to="/test"
					className={`hover:bg-green-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2 lg:border-l-0  ${
						isCurrentPath('/test') ? 'bg-green-900 text-white ' : ''
					}`}
				>
					{lang === 'en' ? "List of Test's/Exam's" : 'পরীক্ষা/টেস্ট তালিকা'}
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="blue" className="font-normal">
				<a
					target="_blank"
					href="http://103.230.62.205:9091/medicare-web-client-v2/#/login"
					className="hover:bg-blue-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2 lg:border-l-0"
					rel="noreferrer"
				>
					{lang === 'en' ? 'Patient Portal' : ' রোগীর পোর্টাল'}
				</a>
			</Typography>
			<Typography as="li" variant="small" color="red" className="font-normal">
				<Link
					to="/ict"
					className={`hover:bg-red-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2 lg:border-l-0  ${
						isCurrentPath('/ict') ? 'bg-red-900 text-white ' : ''
					}`}
				>
					{lang === 'en' ? 'ICT' : ' আইসিটি'}
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="green" className="font-normal">
				<a
					target="_blank"
					// href="https://mail.zoho.com/zm/#mail/folder/inbox"
					href="https://btclwebhosting-m1.com.bd:2096"
					className="hover:bg-green-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2"
					rel="noreferrer"
				>
					{lang === 'en' ? 'Web Mail' : 'ওয়েব মেইল'}
				</a>
			</Typography>
			<Typography as="li" variant="small" color="red" className="font-normal">
				<a href="#">
					<GalleryDrop />
				</a>
			</Typography>
			<Typography as="li" variant="small" color="green" className="font-normal">
				<Link
					to="/current-affairs"
					className={`hover:bg-green-900 hover:text-white border-0 lg:border-gray-500 lg:border-[1px] lg:border-t-0 px-6 py-2 lg:border-l-0  ${
						isCurrentPath('/current-affairs') ? 'bg-green-900 text-white ' : ''
					}`}
				>
					{lang === 'en' ? 'Ongoing Activities' : 'চলমান কার্যক্রম'}
				</Link>
			</Typography>
			<Typography as="li" variant="small" color="red" className="font-normal">
				<a href="#">
					<JournalDrop />
				</a>
			</Typography>
			<Typography as="li" variant="small" color="green" className="font-normal">
				<a href="#">
					<NoticeDrop />
				</a>
			</Typography>
		</ul>
	);

	return (
		<div>
			<Navbar className="mx-auto max-w-screen-xl py-2 px-4  rounded-sm mt-2">
				<div className="container mx-auto flex items-center justify-between text-blue-gray-900">
					<div className="hidden lg:block">{navList}</div>

					<IconButton
						variant="text"
						className="mr-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
						ripple={false}
						onClick={() => setOpenNav(!openNav)}
					>
						{openNav ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								className="h-6 w-6"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4 6h16M4 12h16M4 18h16"
								/>
							</svg>
						)}
					</IconButton>
				</div>
				<MobileNav open={openNav}>
					<div className="container mx-auto">{navList}</div>
				</MobileNav>
			</Navbar>
			<div className="bg-gray-200 rounded-sm mt-2 p-1 ">
				<marquee
					behavior=""
					direction=""
					scrollamount="5"
					className="text-[#ff5349] font-bold text-lg flex justify-center items-center"
				>
					{lang === 'en' ? data.en : data.bn}
				</marquee>
			</div>
		</div>
	);
}
