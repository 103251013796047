import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ScrollRestoration, useParams } from "react-router-dom";
import { server } from "../server";
import Loader from "../components/shared/Loader";

const VideoGalleryDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/videoGallery/get-video_gallery`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.videoGallery);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }
  const item = data.find((i) => i._id === id);

  const desc = item.description.bn.split("।");
  const descEn = item.description.en.split(".");

  let upDescription = "";
  for (let index = 0; index < Math.floor(desc.length / 2); index++) {
    upDescription += desc[index] + "।";
  }
  let upDescriptionEn = "";
  for (let index = 0; index < Math.floor(descEn.length / 2); index++) {
    upDescriptionEn += desc[index] + ".";
  }

  let downDescription = "";
  for (let index = Math.floor(desc.length / 2); index < desc.length; index++) {
    downDescription += desc[index] + "।";
  }
  let downDescriptionEn = "";
  for (
    let index = Math.floor(descEn.length / 2);
    index < descEn.length;
    index++
  ) {
    downDescriptionEn += descEn[index] + ".";
  }

  return (
    <div>
      <div className="px-0 lg:px-2 mt-4">
        <Typography className="text-red-600 text-2xl font-semibold">
          {lang === "en" ? item.title.en : item.title.bn}
        </Typography>
        <hr className="my-3" />
        <Typography className="text-sm text-justify mb-4">
          {lang === "en" ? upDescriptionEn : upDescription}
        </Typography>
        <iframe
          src={item.link}
          className="w-full h-80 rounded-md"
          frameborder="0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <Typography className="text-sm text-justify mt-4">
          {lang === "en" ? downDescriptionEn : downDescription}
        </Typography>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default VideoGalleryDetails;
