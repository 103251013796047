import React, { useEffect, useState } from "react";
import allData from "../static/homeData";

import { MdKeyboardArrowRight, MdPlayArrow } from "react-icons/md";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import "../assets/css/Homepage.css";
import { server } from "../server";

const Homepage = () => {
  const lang = localStorage.getItem("language");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${server}/news/get-news`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData.news);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  console.log("I am from BTCL")

  return (
    <div className="w-full mt-2">
      <div className="flex bg-gray-200 items-center gap-5 rounded-sm mt-2 mx-0 lg:mx-2 mb-2 px-1 h-10 overflow-hidden">
        <div className="scrolling-container">
          <ul className="inner-content">
            {data.map((d, index) => (
              <li key={index} className="mt-4">
                <a
                  target="_blank"
                  href={d.link}
                  className="text-sm text-justify"
                >
                  {lang === "en" ? d.title.en : d.title.bn}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Link
          to="/emergency-notice"
          className="bg-green-500 h-6 flex items-center text-white rounded-sm p-2"
        >
          {lang === "en" ? "All" : "সকল"}
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full px-0 lg:px-2">
        {allData.map((data) => {
          return (
            <div key={data.id} className="w-full">
              <Card className="rounded-sm transition-all hover:rounded-2xl hover:drop-shadow-[0px_0px_6px_rgba(217,10,0,0.30)] w-full md:w-full h-[300px]
            ">
                <CardBody className="">
                  <Typography className=" font-semibold mb-3 text-red-500">
                    {lang === "en" ? data.title.en : data.title.bn}
                  </Typography>
                  <div className="flex items-start justify-between">
                    <div className="w-[70px] h-[70px]">
                      <img
                        className="w-full object-cover"
                        src={data.img}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col">
                      {data.sub.map((d) => {
                        return (
                          <div
                            key={d.id}
                            className="flex items-start gap-1 w-44"
                          >
                            <span className="text-green-800">
                              <MdKeyboardArrowRight className="text-xl"/>
                            </span>
                            <Link to={`/site-selection/${d.id}`}>
                              {" "}
                              <p className="text-sm text-black hover:underline hover:text-green-900 ">
                                {lang === "en" ? d.name.en : d.name.bn}
                              </p>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Homepage;
