export default function AimsAndScope() {
	return (
		<section className="journal-description  bg-gray-100 text-gray-800 rounded-sm">
			<p className="text-justify">
				The National Institute of Laboratory Medicine and Referral Centre
				(NILMRC) in Dhaka, Bangladesh, serves as a leading facility for
				diagnostic, referral, and research services. Additionally, the institute
				contributes to the global scientific community by publishing research
				findings in medical journals, advancing the knowledge base in laboratory
				medicine, infectious disease epidemiology, and public health policy.
				National Institute of Laboratory medicine and referral center,
				Journal(NILMRCJ) is the official organ of the National Institute of
				Laboratory medicine and referral center, Dhaka, Bangladesh and published
				twice in a year in the months of January and December. This journal has
				applied for Bangladesh Medical and Dental Council (BM&DC) and will do so
				soon. The Journal publishes original articles, review articles, case
				reports and short communications related to various branches of medical
				sciences.
			</p>
		</section>
	);
}
